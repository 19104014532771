"use client";
import React from "react";
import "./Button.scss";
import Link from "next/link";

export interface ButtonProps {
  type?: "primary" | "secondary" | "tertiary";
  loading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  href?: string;
  children: React.ReactNode;
  external?: boolean;
}

/**
 *
 * Button
 *
 */
const Button = ({
  type = "primary",
  loading = false,
  onClick = () => {},
  disabled = false,
  icon = null,
  className = "",
  href = null,
  size = "default",
  children = null,
  color = "bg-black",
  ...props
}) => {
  const styles = {};

  const Icon = icon || null;
  const Component = href ? Link : "button";

  const textColors = {
    "bg-red": "text-white",
    "bg-yellow": "text-black",
    "bg-black": "text-white",
    "bg-[#1f9fbb]": "text-white",
  };

  return (
    <Component
      href={href}
      disabled={disabled}
      onClick={onClick}
      className={`button font-semibold rounded-think-sm ${
        loading
          ? "cursor-not-allowed bg-stone-50 dark:bg-stone-800"
          : `${color} ${textColors[color]} hover:bg-black hover:text-white `
      } group my-2 flex ${
        size === "default" ? " h-15" : "h-10"
      } w-full px-8 md:px-12 items-center justify-center text-center space-x-2 rounded-thinknado transition-all duration-300 ${className}`}
      {...props}
    >
      <span className="inner">
        {loading ? (
          // <LoadingDots color="#A8A29E" />
          <span>Loading...</span>
        ) : (
          <>
            {!!icon && <span className="icon">{icon}</span>}

            <span
              className={`${
                size === "defualt" ? "type-button" : "type-button-small"
              } font-800 text-md sm:text-xl`}
            >
              {children}
            </span>
          </>
        )}
      </span>
    </Component>
  );
};

export default Button;
